import { Button, Table, Divider, Row, Col, Flex } from 'antd';
import React from 'react';
import { FormattedMessage, IntlProvider } from "react-intl";
import Swal from 'sweetalert2';

import { FaGithub, FaInfoCircle } from "react-icons/fa";
import "./Projects.css";

const data = [
    {
        key: '1',
        name: 'HR Management',
        libraries: "React, Spring Boot, RabbitMQ, Kubernetes,PostgreSQL"
    },
    {
        key: '2',
        name: 'Microservice',
        libraries: "Spring Boot,  Kubernetes, Docker, Redis,  RabbitMQ,  PostgreSQL, MongoDB",
    },
    {
        key: '3',
        name: 'Social Media App',
        libraries: "Spring Boot,  Kubernetes, Docker, Redis,  RabbitMQ,  PostgreSQL, MongoDB",
    },
    {
        key: '4',
        name: 'Blog App',
        libraries: "Spring Boot, PostgreSQL",
    },
    {
        key: '5',
        name: 'EduCraft',
        libraries: "HTML5, CSS3",
    },
    {
        key: '6',
        name: 'Rick & Morty',
        libraries: "HTML5, CSS3,JS,React",
    },
    {
        key: '7',
        name: 'DailyNotes',
        libraries: "Spring Boot, React, PostgreSQL",
    },
];


const messages = {
    "tr": {
        name: "İsim",
        libraries: "Kütüphaneler",
        title: "Projelerim",
        text: "Burası text",
        hrManagement: "HR Management projesi misafir/şirket yöneticisi ya da admin olarak kayıt-giriş yapabileceğiniz. Harcamalar, gelirler gibi şirket bilgilerini görünteleyebileceğiniz. Misafir olarak giriş yapan insanları şirket yöneticisi olarak kendi şirketinizde çalışan yapabileceğiniz. Şirket hakkında yorum yapabileceğiniz ve şirket yöneticisi onaylarsa diğerleri tarafından yorumların görüntülenebileceği bir projedir.",
        microservice: "Mikroservis projesi kısaca mikroservis mimarisine giriş yapılan projedir. RabbitMQ, Redis, Elasticsearch, Kubernetes gibi araçların kullanıldığı projedir.",
        socialMedia: "Social Media projesi konuk, şirket yöneticisi ya da admin olarak kayıt olabileceğiniz ve giriş yapabileceğiniz; Şirket bilgilerini gözlemleyebileceğiniz, şirket giderlerini ya da çalışanların yorumlarını görebileceğiniz, sizin de aynı zamanda bir şirket hakkında yorum yapabileceğiniz bir projedir",
        ricknmorty: "Rick & Morty projesi bir React.js giriş projesidir. Fetch ve axios kullanılıp, FakeApi'den veri çekilmiştir",
        blogApp: "Blog App insanların kayıt olup, yorum yapabileceği, diğer insanlarla etkişelim kurabileceği bir monolitik projedir",
        eduCraft: "EduCraft projesi HTML5 vs CSS3 ile Figma üzerinden bir tasarımın benzerini yapmaya dayanmaktadır.",
        dailyNotes: "Bu proje yapım aşamasındadır.",
        action: "İşlemler",
        repo: "Gitmek istediğiniz repositoryi seçiniz",
        ok: "Tamam"

    },
    "en": {
        name: "Name",
        libraries: "Libraries",
        title: "My Projects",
        text: "This is text",
        hrManagement: "HR Mangement is a project you can  register and sign in as guest,company manager or admin; you can track your company informations such as finance, expenses; you can make comments and view other people’s comments about your company.",
        microservice: "Microservice project is simply an entrance project to microservice architecture. It is a project I have learned and developed tools like RabbitMQ, Redis, Elasticsearch, Kubernetes.",
        socialMedia: "The Social Media Project is a platform designed to increase interaction and transparency between companies and their employees. On the platform, you can register and log in as a guest, company manager, or administrator.",
        blogApp: "Blog App is a monolithic project where people can register and login. Post comments and see other people's comments",
        ricknmorty: "Rick and Morty project is my React entrance project. I've learned axios, fetch and how to use FakeApi",
        eduCraft: "EduCraft project is based on using HTML5 and CSS3 creating a similar design from Figma",
        dailyNotes: "Under construction",
        action: "Actions",
        repo: "Choose the repository you want to  go to.",
        ok: "Okay"
    },
    "de": {
        name: "Naam",
        libraries: "Bibliotheken",
        title: "Meine Projekte",
        text: "Ich bin Alperen",
        hrManagement: "HR Management projekte",
        microservice: "Microservice Projekte",
        socialMedia: "Social Media Projekte",
        ricknmorty: "Rick & Morty Projekte",
        action: "Aktionen",
        repo: "",
        ok: "Okay"
    }
};


const Projects = ({ language, contentSize }) => {

    const handleInfo = (record) => {
        const { key } = record;
        if (key === "1") {
            Swal.fire({
                title: "HR Management",
                text: messages[language].hrManagement,
                confirmButtonText: messages[language].ok
            })
        } else if (key === "2") {
            Swal.fire({
                title: "Microservice",
                text: messages[language].microservice,
                confirmButtonText: messages[language].ok
            })
        } else if (key === "3") {
            Swal.fire({
                title: "Social Media App",
                text: messages[language].socialMedia,
                confirmButtonText: messages[language].ok
            })
        } else if (key === "4") {
            Swal.fire({
                title: "Blog App",
                text: messages[language].blogApp,
                confirmButtonText: messages[language].ok
            })
        } else if (key === "5") {
            Swal.fire({
                title: "EduCraft",
                text: messages[language].eduCraft,
                confirmButtonText: messages[language].ok
            })
        } else if (key === "6") {
            Swal.fire({
                title: "Rick & Morty",
                text: messages[language].ricknmorty,
                confirmButtonText: messages[language].ok
            })
        } else if (key === "7") {
            Swal.fire({
                title: "DailyNotes",
                text: messages[language].dailyNotes,
                confirmButtonText: messages[language].ok
            })
        }
    }
    const handleGithub = (record) => {
        const { key, name } = record;
        console.log(`Clicked Github Button for ${name} (Key: ${key})`);
        if (key === "1") {
            Swal.fire({
                title: "HR Management",
                text: messages[language].repo,
                showCancelButton: true,
                confirmButtonText: "React",
                cancelButtonText: "Spring Boot",
                confirmButtonColor: "#66CCCC",
                cancelButtonColor: "#A0DB8E"
            }).then((result) => {
                if (result.isConfirmed) {
                    window.open("https://github.com/alperensertoglu/HRManagement-React")
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    window.open("https://github.com/alperensertoglu/HRManagement-Spring")
                }
            })
        } else if (key === "2") {
            window.open("https://github.com/alperensertoglu/MicroService.git")
        } else if (key === "3") {
            window.open("https://github.com/alperensertoglu/social-media.git")
        } else if (key === "4") {
            window.open("https://github.com/alperensertoglu/BlogApp")
        } else if (key === "5") {
            window.open("https://github.com/alperensertoglu/EduCraft.git")
        } else if (key === "6") {
            window.open("https://github.com/alperensertoglu/rick-and-morty.git")
        }

    }
    const columns = [
        {
            title: "No",
            fixed: "left",
            dataIndex: "key",
            render: (_, __, index) => index + 1,
            responsive: ["xs", "sm", "md", "lg", "xl"],
        },
        {
            title: <FormattedMessage id='name' />,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            responsive: ["xs", "sm", "md", "lg", "xl"],
            ellipsis: true,
        },
        {
            title: <FormattedMessage id='libraries' />,
            dataIndex: 'libraries',
            key: 'lbiraries',
            fixed: 'left',
            responsive: ["xs", "sm", "md", "lg", "xl"],
            ellipsis: true,
        },
        {
            title: <FormattedMessage id='action' />,
            dataIndex: 'action',
            fixed: "left",
            key: 'action',
            render: (_, record) => (
                <div className='action-buttons'>
                    <Button onClick={() => handleInfo(record)}>
                        <FaInfoCircle className="infoButton" style={{ cursor: "pointer" }} />
                    </Button>
                    <Button onClick={() => handleGithub(record)}>
                        <FaGithub className='githubButton' style={{ cursor: "pointer" }} />
                    </Button>
                </div>
            ),
            responsive: ["xs", "sm", "md", "lg", "xl"],
            ellipsis: true,
        }
    ];
    return (
        <IntlProvider messages={messages[language]} locale={language}>
            <Flex justify='center' align='center' style={{ maxWidth: contentSize.width, maxHeight: contentSize.height }}>
                <Row gutter={0}>
                    <Divider><FormattedMessage id='title' /></Divider>
                    <Col span={24}>
                        <Table
                            className='dataTable'
                            columns={columns}
                            dataSource={data}
                            size='small'
                            scroll={{
                                x: "max-content",
                                y: 400
                            }}
                            pagination={{
                                pageSize: 10
                            }}
                            bordered
                        />
                    </Col>
                </Row>
            </Flex>
        </IntlProvider >


    );
}

export default Projects;
