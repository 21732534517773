import { Button, Dropdown, Layout, Menu } from 'antd';
import { SiGoogletranslate } from "react-icons/si";
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

const { Header } = Layout;

const items = [
    {
        label: 'home',
        path: "/",
    },
    {
        label: "projects",
        path: "/projects",
    },
    {
        label: "info",
        path: "/info"
    },
];

const messages = {
    tr: {
        home: "Ana Sayfa",
        info: "Hakkımda",
        projects: "Projelerim",
    },
    en: {
        home: "Home",
        info: "About Me",
        projects: "Projects",
    },
    de: {
        home: "Hauptseite",
        info: "Über mich",
        projects: "Projekte",
    }
};

function Headers({ language, setLanguage }) {
    const MenuItem = ({ path, label, language }) => {
        const { pathname } = useParams();
        return (
            <Menu.Item key={path} className={pathname === path ? 'ant-menu-item-selected' : ''}>
                <Link to={path}>
                    <FormattedMessage id={label} defaultMessage={label} values={{ language }} />
                </Link>
            </Menu.Item>
        );
    };

    const LanguageMenu = (
        <Menu>
            <Menu.Item key="tr" onClick={() => setLanguage("tr")}>TR</Menu.Item>
            <Menu.Item key="en" onClick={() => setLanguage("en")}>EN</Menu.Item>
            {/* <Menu.Item key="de" onClick={() => setLanguage("de")}>DE</Menu.Item> */}
        </Menu>
    );

    return (
        <IntlProvider locale={language} messages={messages[language]}>
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div className="demo-logo" />
                <Menu
                    theme="dark"
                    mode="horizontal"
                    className='custom-menu'
                    style={{
                        flex: 1,
                        minWidth: 0,
                    }}
                >
                    {items.map((item, index) => (
                        <MenuItem key={index} path={item.path} label={item.label} language={language} />
                    ))}
                </Menu>
                <div style={{ position: "absolute", right: "2%", top: "1%" }}>
                    <Dropdown overlay={LanguageMenu} >
                        <Button className="language-button">
                            <span className="language-icon"><SiGoogletranslate style={{ fontSize: 24 }} /></span>
                        </Button>
                    </Dropdown>
                </div>
            </Header>
        </IntlProvider>
    )
}

export default Headers;
