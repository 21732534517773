import { Button, Col, Flex, Row } from 'antd';
import React, { useState } from 'react';
import { FaFilePdf, FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FormattedMessage, IntlProvider } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Resim from "../../assets/images/IMG_20231119_155142_494_Original.jpeg";
import './Home.css';

function downloadPdf() {
    const fileId = "1mRJ1m2z1y7sf6uHD1SOBOms6FFoWP3az"; // Dosya ID'si
    const downloadUrl = `https://drive.google.com/uc?export=download&id=${fileId}`;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "AlperenSertoğlu.pdf";
    link.click();

}

function Circle({ imageUrl, height, width }) {
    return (
        <div style={{ height: height, width: width }}>
            <img src={imageUrl} alt="Circle" className="circle-image" />
        </div>
    );
}

function Home({ language, contentSize }) {
    const navigate = useNavigate();
    const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);

    const messages = {
        tr: {
            title: "Hoşgeldiniz",
            hello: "Merhaba",
            info: "Hakkımda",
            projects: "Projelerim",
            github: "Github profilim",
            linkedin: "Linkedin profilim",
        },
        en: {
            title: "Welcome",
            hello: "Hello",
            info: "About Me",
            projects: "My Projects",
            github: "My Github profile",
            linkedin: "My linkedin profile",
        },
        de: {
            title: "Wilkommen"
        }
    };

    const handleButtonClick = () => {
        setShowAdditionalButtons(!showAdditionalButtons);
    };

    return (
        <IntlProvider messages={messages[language]} locale={language}>
            <Flex vertical align='center' justify='center' gap={10} style={{ height: contentSize.height, width: contentSize.width, backgroundColor: "#E2DFF1", borderRadius: "2%" }}>
                {/* Resim */}
                <Row>
                    <Col span={24}>
                        <Flex className='image-container' justify='center' align='center'>
                            <Circle className="image" imageUrl={Resim} />
                        </Flex>
                    </Col>
                </Row>
                {/* Butonlar */}
                <Row>
                    <Col span={24}>
                        <Flex align='center' justify='center'>
                            <Flex>
                                <Button type='text' shape='round' style={{ width: "30vw", height: "8vh", backgroundColor: '#257586' }} onClick={handleButtonClick}>
                                    <FormattedMessage id='title' />
                                </Button>
                                {showAdditionalButtons && (
                                    <Flex>
                                        <Button type='text' shape='round' style={{ width: "30vw", height: "8vh", backgroundColor: "#3D8493" }} className={showAdditionalButtons ? 'appear-animation' : ''} onClick={() => {
                                            navigate("/projects")
                                        }}>
                                            <FormattedMessage id='projects' />
                                        </Button>
                                        <Button type='text' shape='round' style={{ width: "30vw", height: "8vh", backgroundColor: "#5593A1" }} className={showAdditionalButtons ? 'appear-animation' : ''}
                                            onClick={() => {
                                                navigate("/info")
                                            }}>
                                            <FormattedMessage id='info' />
                                        </Button>
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>
                    </Col>
                </Row>
                {/* Alttaki ikonlar */}
                <Row>
                    <Col span={24} style={{ bottom: 1 }}>
                        <Button type='text' className='button-bottom' shape='round' style={{ width: "60vw", height: "8vh" }}>
                            <Flex justify='space-between' align='center' className='icon-flex'>
                                <FaFilePdf className='icon' onClick={downloadPdf} />
                                <FaGithub className='icon' onClick={() => {
                                    window.open("https://github.com/alperensertoglu")
                                }} />
                                <FaLinkedin className='icon' onClick={() => {
                                    window.open("https://www.linkedin.com/in/alperen-sertoglu/")
                                }} />
                                <FaInstagram className='icon' onClick={() => {
                                    window.open("https://www.instagram.com/alpersertoglu")
                                }} />
                            </Flex>
                        </Button>
                    </Col>
                </Row>
            </Flex >
        </IntlProvider >
    );
}

export default Home;
