import { Card, Col, Divider, Flex, Row, Typography } from 'antd';
import React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';

import { FaFilePdf, FaGithub, FaLinkedin } from 'react-icons/fa';
import "./Info.css";


const { Text } = Typography;
function downloadPdf() {
    const fileId = "1DM_KVJxhBotr5ebH9JHdV2EFUrC8jP8w"; // Dosya ID'si
    const downloadUrl = `https://drive.google.com/uc?export=download&id=${fileId}`;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "AlperenSertoğlu.pdf";
    link.click();
}

function Info({ language, contentSize }) {
    const messages = {
        tr: {
            title: 'Hakkımda',
            text:
                "Fethiye'de tamamladığım ilk ve orta öğrenimimin ardından, Aydın Adnan Menderes Üniversitesi'nde Bitki Koruma lisans eğitimi aldım. Lisans yıllarımda yazılıma olan tutkum her geçen gün arttı ve kendimi bu alanda geliştirmeye karar verdim. Teknolojinin sunduğu imkanları kullanarak geleceğe yön vermek ve yeni ürünler ortaya koymak beni her zaman cezbetmiştir. Lisans eğitimimi tamamladıktan sonra, içimdeki yazılım tutkusu hala sönmediği için kendimi yoğun bir eğitim arayışına buldum. Bu arayışım sonucunda Bilge Adam ile tanıştım ve son teknolojiyi ve uygulamalı dersleri içeren Full-Stack Developer programına dahil oldum. 6 ay boyunca Spring ve React teknolojileri üzerine eğitimin yanı sıra uygulama dersleri pekiştirme imkanı bulduğum yoğun bir eğitim süreci geçirdim. Bu süreçte, yazılım dilinin ve teknolojinin sürekli yenilendiğini ve bu alanda öğrenmenin asla bitmeyeceğini gördüm. Bu durum beni her geçen gün daha da motive etti ve kendimi geliştirmeye olan tutkumu pekiştirdi. Sürekli öğrenmeye ve kendimi geliştirmeye açık bir birey olarak, her zaman daha iyisi olmayı hedefliyorum. Bu hedef doğrultusunda, yazılım alanındaki yenilikleri takip etmeye ve yeni teknolojileri öğrenmeye devam edeceğim.",
            contact: "İletişim",
        },
        en: {
            title: 'About me',
            text:
                "After completing my primary and secondary education in Fethiye, I received a Bachelor's degree in Plant Protection from Adnan Menderes University in Aydın. During my undergraduate years, my passion for software grew with each passing day, and I decided to develop myself in this field. I have always been fascinated by the possibilities offered by technology and the prospect of using it to shape the future and create new products. After graduating, driven by my unwavering passion for software, I embarked on an intensive search for further education. This led me to Bilge Adam, where I enrolled in their Full-Stack Developer program, which featured cutting-edge technology and hands-on application classes. Over the course of 6 months, I underwent a rigorous training program that focused on Spring and React technologies, alongside practical application classes that solidified my learning. This experience opened my eyes to the ever-evolving nature of software languages and technologies, and the realization that learning in this field never ceases. This realization motivated me even more and reinforced my passion for self-improvement. As an individual who is open to continuous learning and development, I always strive to be the best version of myself. To this end, I will continue to follow the latest innovations in the software field and learn new technologies.",
            contact: "Contact",
        },
        de: {
            title: 'Ich bin Alperen',
            text:
                "Nach Abschluss meiner Grund- und Sekundarschulausbildung in Fethiye erwarb ich einen Bachelor-Abschluss in Pflanzenschutz an der Adnan Menderes Universität in Aydın. Während meines Studiums wuchs meine Leidenschaft für Software mit jedem Tag und ich beschloss, mich in diesem Bereich weiterzuentwickeln. Die Möglichkeiten, die Technologie bietet, und die Aussicht, sie zu nutzen, um die Zukunft zu gestalten und neue Produkte zu entwickeln, haben mich schon immer fasziniert. Nach meinem Abschluss und getrieben von meiner ungebrochenen Leidenschaft für Software begab ich mich auf eine intensive Suche nach Weiterbildungsmöglichkeiten. Dies führte mich zu Bilge Adam, wo ich mich für das Full-Stack-Developer-Programm anmeldete, das modernste Technologie und praktische Anwendungskurse umfasste. In einem 6-monatigen, rigiden Trainingsprogramm vertiefte ich meine Kenntnisse in den Bereichen Spring- und React-Technologien und nahm an praktischen Anwendungskursen teil, die mein Gelerntes festigten. Diese Erfahrung öffnete mir die Augen für die sich ständig weiterentwickelnde Natur von Softwaresprachen und -technologien und die Erkenntnis, dass das Lernen in diesem Bereich niemals aufhört. Diese Erkenntnis motivierte mich noch mehr und verstärkte meine Leidenschaft für die Selbstverbesserung. Als Person, die offen für kontinuierliches Lernen und Entwicklung ist, strebe ich immer danach, die beste Version meiner selbst zu sein. Zu diesem Zweck werde ich weiterhin die neuesten Innovationen im Softwarebereich verfolgen und neue Technologien lernen.",
            contact: "Kommunikation"
        },
    };

    return (
        <IntlProvider messages={messages[language]} locale={language}>
            <Flex vertical gap={5} style={{ height: contentSize.height, marginTop: "5%" }}>
                <Row>
                    <Col span={24}>
                        <Divider style={{ fontSize: 22 }}> <FormattedMessage id='title' /></Divider>
                        <div className="scrollable-text" style={{ maxHeight: '250px', overflow: 'auto' }}>
                            <Text about='info' italic>
                                <FormattedMessage id='text' />
                            </Text>
                        </div>
                    </Col>
                </Row>
                <Divider style={{ fontSize: 22 }}><FormattedMessage id='contact' /> </Divider>
                <Row gutter={5}>
                    <Col span={8}>
                        <Card
                            hoverable
                            onClick={() => window.open('https://github.com/alperensertoglu')}
                        >
                            <Flex gap={5} justify='center'>
                                <FaGithub className='icon' />
                                <Text className='ml-1'>Github</Text>
                            </Flex>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            hoverable
                            onClick={() => window.open('https://www.linkedin.com/in/alperen-sertoglu/')}>
                            <Flex gap={5} justify='center'>
                                <FaLinkedin className='icon' />
                                <Text className='ml-1'>LinkedIn</Text>
                            </Flex>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card hoverable onClick={downloadPdf}>
                            <Flex gap={5} justify='center'>
                                <FaFilePdf className='icon' />
                                <Text className='ml-1'>CV</Text>
                            </Flex>
                        </Card>

                    </Col>
                    <Divider onClick={() => window.open("mailto:alperensertoglu@hotmail.com")} style={{ cursor: "pointer" }}>alperensertoglu@hotmail.com</Divider>
                </Row>


            </Flex>
        </IntlProvider >
    );
}


export default Info;
