import { Layout, Flex } from 'antd';
import React from 'react';
import Measure from 'react-measure';
import { Route, Routes } from 'react-router-dom';

import Home from "../../pages/home/Home";
import Info from '../../pages/info/Info';
import Projects from "../../pages/projects/Projects";



const { Content } = Layout;

function Body({ language }) {
    const contentSize = ({ width: "98.5vw", height: "88vh" });

    return (
        <Flex justify='center' align='center'>
            <Content
                style={{
                    padding: '0 48px',
                    backgroundColor: "#F8F9F9",
                    margin: 10,
                    height: contentSize.height,
                    width: contentSize.width,
                    borderRadius: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Measure>
                    {({ measureRef }) => (
                        <div ref={measureRef}>
                            <Routes>
                                <Route path="/projects" element={<Projects language={language} contentSize={contentSize} />} />
                                <Route path="/info" element={<Info language={language} contentSize={contentSize} />} />
                                <Route path="/" element={<Home language={language} contentSize={contentSize} />} />
                            </Routes>
                        </div>
                    )}
                </Measure>
            </Content>
        </Flex>
    )
}

export default Body