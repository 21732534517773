import { Layout } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Body from './components/Body/Body';
import Header from './components/Header/Header';

import './App.css';

const App = () => {
  const isLocal = localStorage.getItem("local");
  const [language, setLanguage] = useState(isLocal ? isLocal : "en");

  useEffect(() => {
    localStorage.setItem("local", language);
  }, [language]);

  return (
    <Router>
      <Layout style={{ backgroundColor: "#2C3E50", display: "flex", justifyContent: "center" }}>
        <Header language={language} setLanguage={setLanguage} />
        <Body language={language} />
      </Layout>
    </Router>
  );
};

export default App;
